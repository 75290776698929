<template>
    <div class="px-4 pb-4">
        <div>
            <div class="categories-container">
                <div class="my-2" v-for="category, index in categories" :key="index" @click="redirectToParticularCategory(category)">
                    <div class="px-2 clickable-pointer category-tile">
                        <img v-if="isMobile" class="rounded" :src="'https://d3vsnl8idgwrlw.cloudfront.net/' +  category.cat_image_mobile" alt="cat-image">
                        <img v-else-if="isTablet" class="rounded" :src="'https://d3vsnl8idgwrlw.cloudfront.net/' +  category.cat_image_tablet" alt="cat-image">
                        <img v-else class="rounded" :src="'https://d3vsnl8idgwrlw.cloudfront.net/' +  category.cat_image_web" alt="cat-image">
                    </div>
                    <!-- <category-subcomponent class="category-class" @redirectToParticularCategory="redirectToParticularCategory(category)" :category_data="category" category_type="ePharmacy"></category-subcomponent> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CategorySubComponent',
    props: {
        categories: {
            type: Array,
            required: true
        },
        header_data: {
            type: Object,
            required: false
        }
    },
    components: {
        'category-subcomponent': () => import('./categorySubComponent.vue')
    },
    data() {
        return {
            screenWidth: window.innerWidth,
            categoryLength: null,
            categoryComponentKey: 0,
            isMobile: null,
            isTablet: null
        }
    },
    watch: {
		screenWidth(newWidth, oldWidth) {
			console.log('Screen Width Changed:', newWidth);

            this.isMobile = this.screenWidth <= 600 ? true : false;
            this.isTablet = this.screenWidth > 600 && this.screenWidth <= 1264 ? true : false;
		},
	},
    mounted() {
        this.screenWidth = window.innerWidth;

        this.isMobile = this.screenWidth <= 600 ? true : false;
        this.isTablet = this.screenWidth > 600 && this.screenWidth <= 1264 ? true : false;

        window.addEventListener('resize', this.handleResize);      
    },
    beforeUnmount() {
		window.removeEventListener('resize', this.handleResize);
	},
    methods: {
        handleResize() {
			console.log('Handle Resize method called');
            this.screenWidth = window.innerWidth;

            this.$emit('handleComponentSizeChange');
		},
        redirectToParticularCategory(category) {
            this.$router.push({
                name: 'CatalogPage',
                params: {
                    catalog_name: 'categories',
                    category_id: category.id
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
// .categories-container {
//     display: flex;
//     flex-wrap: wrap;
//     width: 100%;
//     gap: 24px;
// }

.categories-container {
    display: grid;

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1265px) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.category-tile {
    img {
        width: 100%;
        height: auto;
        object-fit: fill;
        object-position: right bottom;
    }
}

.category-class {
    width: 100px;
}
</style>

<style lang="scss">
@import '../../scss/classes.scss';
</style>